export interface DateNotifications {
  [date: number]: RestaurantsNotifications;
}

export interface RestaurantsNotifications {
  [restaurantId: string]: Notifications;
}

export interface Notifications {
  functions: FunctionsNotifications;
  // other ...
}
export interface FunctionsNotifications {
  [notificationId: string]: FunctionNotification;
}

export interface FunctionNotification {
  type: "functionBooking";
  bookingId: string;
  name: string;
  customerId: string;
  timeStamp: number;
  alternateCustomerId?: string;
  start?: string;
  end?: string;
  status: number;
  source: number;
}

export enum AllTimeNotificationsType {
  FunctionPayment = 1,
  FunctionOverdue,
}

export type AllTimeNotification = {
  _key?: string;
  createdAt: number;
  type: AllTimeNotificationsType;
  functionBookingId: string;
  customerName: string;
  customerId: string;
  alternateCustomerId: string;
  date: number;
  restaurantId: string;
  functionName: string;
  enabled: boolean;
} & (FunctionPaymentNotification | FunctionOverdueNotification);

export type FunctionPaymentNotification = {
  type: AllTimeNotificationsType.FunctionPayment;
  invoiceNumber: number;
  invoiceId: string;
  invoiceAmount: number;
  paidAmount: number;
  paidTimeStamp: number;
  invoiceDescription: string;
  serviceFeeAmount: number;
};

export type FunctionOverdueNotification = {
  type: AllTimeNotificationsType.FunctionOverdue;
  paymentScheduleId: string;
  amount: number;
  dueDate: number;
  description: string;
};
