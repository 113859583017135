import { LineItem, PaymentPriceAdjustment, PaymentType, Transaction, TransactionSurcharge } from "./transaction";

export interface TabGuests {
  [customerId: string]: { tabLimit?: number };
}

export enum PaymentTabType {
  General = 1,
  Deposit,
}

export interface PaymentTabTransaction {
  lineItems: LineItem[];
  payments: PaymentType[];
  grandTotal: number;
  tipAmount: number;
  surcharges: TransactionSurcharge[];
  serviceCharge: TransactionSurcharge;
  serviceFee: number;
  transactionFee: number;
  resbutlerFee: number;
  timeStamp: number;
  bookingId: string;
  functionId: string;
  customerId: string;
  date: number;
  mealId: string;
  salesTax: number;
  restaurantId: string;
  operatorId: string;
  tillId: string;
  paymentPriceAdjustments: PaymentPriceAdjustment[];
}
export interface PaymentTab {
  _key: string;
  date: number;
  amount: number;
  remainingAmount: number;
  bookingId?: string;
  functionBookingId?: string;
  mealId: string;
  restaurantId: string;
  tabName: string;
  tabOwner: string; // customerId
  tabOwnerLimit?: number;
  closed: boolean;
  createdAt: number;
  preOrderFood?: boolean;
  preOrderDrink?: boolean;
  guests?: TabGuests;
  tabType: PaymentTabType;
  firstName: string;
  lastName: string;
  preAuthPayments?: { [id: string]: PaymentType };
  tabTransactions?: { [id: string]: Transaction };
  createTransactionBeforeTabClosed: boolean; // Deposit and Function Tab has value true for this field.
  allowAllGuests?: boolean; // option allows all the guest regardless of their rsvp status to use the tab
  perGuestLimit?: number; // tab limit per guest when allowAllGuests is truthy
}

export interface AvailableTabPayment {
  tab: PaymentTab;
  availableAmount: number;
  paidAmount: number;
  limit: number;
}
